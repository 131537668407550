import {
  ChangeEventHandler,
  ClipboardEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { RegisterOptions } from 'react-hook-form';
import EyeOffIcon from '@assets/icon-eye-off.svg';
import EyeOnIcon from '@assets/icon-eye-on.svg';
import InputCounter from '@atoms/InputCounter';
import IconCloseCircleFill from '@svg-icons/IconCloseCircleFill';
import { get } from '@utils/utils';
import styles from './css';

type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  children?: ReactElement;
  customRef?: RefObject<HTMLInputElement>;
  defaultValue?: null | string;
  enterKeyHint?: string;
  errors?: any;
  icon?: JSX.Element | null;
  inputValue?: string;
  isCopyPasteRestricted?: boolean;
  isPlaceholderSameStyleOfValue?: boolean;
  label?: string;
  maxLength?: number;
  name?: string;
  onBlur?: ((e: FocusEvent<HTMLInputElement>) => void) | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: ((event: Event) => void) | null;
  onClickDeleteButton?: (event: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: ((e: FocusEvent<HTMLInputElement>) => void) | null;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeHolder?: string;
  readOnly?: boolean;
  register?: any;
  registerOptions?: RegisterOptions;
  showDeleteButton?: boolean;
  showInputCounter?: boolean;
  showLabel?: boolean;
  showRequiredLabel?: boolean;
  testId?: string;
  type?: 'email' | 'number' | 'password' | 'tel' | 'text';
  underline?: boolean;
  valueObj?: any;
};

const Input = ({
  autoComplete = 'off',
  autoFocus = false,
  children,
  customRef,
  defaultValue,
  enterKeyHint = 'enter',
  errors = {},
  icon = null,
  inputValue,
  isCopyPasteRestricted,
  isPlaceholderSameStyleOfValue = false,
  label,
  maxLength,
  name,
  onBlur = null,
  onChange = () => {},
  onClick = null,
  onClickDeleteButton = () => {},
  onFocus = null,
  onKeyDown = () => {},
  placeHolder,
  readOnly = false,
  register = () => {},
  registerOptions = {},
  showDeleteButton = false,
  showInputCounter = false,
  showLabel = true,
  showRequiredLabel = false,
  testId,
  type = 'text',
  underline = false,
  valueObj = {},
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('form');

  useEffect(() => {
    if (autoFocus) {
      const node = inputRef.current;
      if (node) {
        node.focus();
      }
    }
  }, []);

  const errorType = get(errors, `${name}.type`);
  let errorTranslationKey;
  if (errorType === 'pattern' || errorType === 'validate') {
    const names = name?.split('.') || [];
    errorTranslationKey = `formError.${errorType}.${names[names.length - 1]}`;
  } else if (errorType === 'manual') {
    errorTranslationKey = `formError.manual.${get(errors, `${name}.message`)}`;
  } else {
    errorTranslationKey = `formError.${errorType}`;
  }

  const inputType = type === 'password' && showPassword ? 'text' : type;

  const restrictCopyPaste = useMemo(() => {
    return isCopyPasteRestricted
      ? {
          onCopy: (e: ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            return false;
          },
          onPaste: (e: ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            return false;
          },
        }
      : null;
  }, [isCopyPasteRestricted]);

  return (
    <div className="form-input">
      <label className="form-input-inner" htmlFor={name}>
        {label && showLabel && (
          <span className="form-label">
            {label}
            {showRequiredLabel && <em>{t('required')}</em>}
          </span>
        )}
        <div className={classNames('input-wrapper', { underline })}>
          {icon && <div className="input-icon">{icon}</div>}
          {children}
          <input
            className={classNames({
              'is-error': errorType,
              'is-place-holder-same-style-of-value':
                isPlaceholderSameStyleOfValue,
              'read-only': readOnly,
            })}
            id={name}
            name={name}
            placeholder={placeHolder}
            type={inputType}
            {...valueObj}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            ref={
              autoFocus
                ? inputRef
                : customRef || register({ ...registerOptions })
            }
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            {...restrictCopyPaste}
            data-testid={testId}
            disabled={readOnly}
            enterKeyHint={enterKeyHint}
            maxLength={maxLength}
            readOnly={readOnly}
            onKeyDown={onKeyDown}
          />

          {showInputCounter && !!maxLength && inputValue !== undefined && (
            <InputCounter inputValue={inputValue} maxLength={maxLength} />
          )}

          {type === 'password' && (
            <button
              className="password-toggle-button"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
            </button>
          )}
          {type === 'text' && showDeleteButton && (
            <button
              className="text-toggle-button"
              type="button"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                }
                inputRef?.current?.focus();
                onClickDeleteButton();
              }}
            >
              <IconCloseCircleFill color="black400" />
            </button>
          )}

          {underline && <div className="round-border" />}
        </div>
      </label>

      {errorType && (
        <div className="form-error">
          {t(errorTranslationKey, {
            label: label ?? '',
            maxLength: String(registerOptions.maxLength),
            minLength: String(registerOptions.minLength),
          })}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default Input;
